<template>
    <main class="main-page"  id="">
        <template v-if="pageReady">
            <template v-if="showHeader">
                <section class="page-section mb-3" >
                    <div class="container">
                        <div class="grid align-items-center">
                            <div  class="col " >
                                <div class=" text-2xl text-primary font-bold" >
                                    Tambah Keranjang
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            <section class="page-section " >
                <div class="container">
                    <div class="grid ">
                        <div  class="md:col-9 sm:col-12 comp-grid" >
                            <div >
                                <form ref="observer" tag="form" @submit.prevent="submitForm()" :class="{ 'card ': !isSubPage }" class=" ">
                                    <div class="grid">
                                        <input name="ctrluser_id"  ref="ctrluser_id" v-model="formData.user_id" type="hidden" />
                                        <input name="ctrlkode_produk"  ref="ctrlkode_produk" v-model="formData.kode_produk" type="hidden" />
                                        <input name="ctrlnama_produk"  ref="ctrlnama_produk" v-model="formData.nama_produk" type="hidden" />
                                        <input name="ctrlharga"  ref="ctrlharga" v-model="formData.harga" type="hidden" />
                                        <input name="ctrlstok_tersedia"  ref="ctrlstok_tersedia" v-model="formData.stok_tersedia" type="hidden" />
                                        <div class="md:col-12 col-12">
                                            Qty *
                                            <div id="ctrl-qty-holder"> 
                                                <InputText  ref="ctrlqty" v-model.trim="formData.qty"  label="Qty" type="number" placeholder="Jumlah Pesanan"   step="any"    
                                                class=" w-full" :class="getErrorClass('qty')">
                                                </InputText>
                                                <small v-if="isFieldValid('qty')" class="p-error">{{ getFieldError('qty') }}</small> 
                                            </div>
                                        </div>
                                        <input name="ctrlstatus_pembayaran"  ref="ctrlstatus_pembayaran" v-model="formData.status_pembayaran" type="hidden" />
                                        <input name="ctrlfoto_produk"  ref="ctrlfoto_produk" v-model="formData.foto_produk" type="hidden" />
                                        <div class="col-12">
                                            Jenis Pengiriman *
                                            <div id="ctrl-jenis_pengiriman-holder"> 
                                                <api-data-source   api-path="components_data/jenis_pengiriman_option_list" >
                                                    <template v-slot="req">
                                                        <Dropdown @update:model-value="(val)=>jenisPengirimanAutofill(val)" class="w-full" :class="getErrorClass('jenis_pengiriman')"   :loading="req.loading"   optionLabel="label" optionValue="value" ref="ctrljenis_pengiriman"  v-model="formData.jenis_pengiriman" :options="req.response" label="Jenis Pengiriman"  placeholder="Jenis Pengiriman" >
                                                        </Dropdown> 
                                                        <small v-if="isFieldValid('jenis_pengiriman')" class="p-error">{{ getFieldError('jenis_pengiriman') }}</small> 
                                                    </template>
                                                </api-data-source>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            Biaya Pengiriman 
                                            <div id="ctrl-biaya_pengiriman-holder"> 
                                                <InputText  ref="ctrlbiaya_pengiriman" v-model.trim="formData.biaya_pengiriman"  label="Biaya Pengiriman" type="text" placeholder="Biaya Pengiriman"    readonly  
                                                class=" w-full" :class="getErrorClass('biaya_pengiriman')">
                                                </InputText>
                                                <small v-if="isFieldValid('biaya_pengiriman')" class="p-error">{{ getFieldError('biaya_pengiriman') }}</small> 
                                            </div>
                                        </div>
                                        <input name="ctrlitem_id"  ref="ctrlitem_id" v-model="formData.item_id" type="hidden" />
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button class="p-button-primary" type="submit" label="Tambah Keranjang" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
    </main>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import { required, numeric, } from 'src/services/validators';
	import { ApiService } from 'src/services/api';
	import { utils } from 'src/utils';
	import { useApp } from 'src/composables/app.js';
	import { useAuth } from 'src/composables/auth';
	import { useAddPage } from 'src/composables/addpage.js';
	import { usePageStore } from 'src/store/page';
	const props = defineProps({
		pageStoreKey: {
			type: String,
			default: 'FORM_PEMESANAN',
		},
		pageName : {
			type : String,
			default : 'form_pemesanan',
		},
		routeName : {
			type : String,
			default : 'form_pemesananadd',
		},
		apiPath : {
			type : String,
			default : 'form_pemesanan/add',
		},
		submitButtonLabel: {
			type: String,
			default: "Tambah Keranjang",
		},
		formValidationError: {
			type: String,
			default: "Form is invalid",
		},
		formValidationMsg: {
			type: String,
			default: "Please complete the form",
		},
		msgTitle: {
			type: String,
			default: "Create Record",
		},
		msgAfterSave: {
			type: String,
			default: "Data berhasil ditambahkan",
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
		pageData: { // use to set formData default values from another page
			type: Object,
			default: () => {} 
		},
	});
	//lines
	const store = usePageStore(props.pageStoreKey);
	const app = useApp();
	const auth = useAuth();
	
	const formDefaultValues = {
		user_id: auth.userId, 
		kode_produk: "", 
		nama_produk: "", 
		harga: "", 
		stok_tersedia: "", 
		qty: "1", 
		status_pembayaran: "Keranjang", 
		foto_produk: "", 
		jenis_pengiriman: "", 
		biaya_pengiriman: "", 
		item_id: utils.randomStr(12), 
	};
	
	const formData = reactive({ ...formDefaultValues });
	
	//vuelidate form validation rules
	const rules = computed(() => {
		return {
			user_id: {  },
			kode_produk: {  },
			nama_produk: {  },
			harga: {  },
			stok_tersedia: {  },
			qty: { required, numeric },
			status_pembayaran: {  },
			foto_produk: {  },
			jenis_pengiriman: { required },
			biaya_pengiriman: {  },
			item_id: {  }
		}
	});
	
	const page = useAddPage({ store, props, formData, rules, beforeSubmit, afterSubmit });
	
	// event raised before submit form
	function beforeSubmit(){
		return true;
	}
	
	// event raised after form submited
	function afterSubmit(response) { 
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		page.setFormDefaultValues(); //reset form data
	}
	
	const {  saving, pageReady, } = toRefs(page.state);
	
	const { submitForm, getErrorClass, getFieldError, isFieldValid,  } = page.methods;
	
	onMounted(()=>{
		const pageTitle = "Form Pemesanan";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
	
	//auto fill other inputs on jenis_pengiriman change
	async function jenisPengirimanAutofill(val){
		const resp = await ApiService.get(`components_data/form_pemesanan_jenis_pengiriman_autofill?value=${val}`);
		const data = resp.data[0];
		if(data){
formData.biaya_pengiriman = data.biaya_pengiriman;
		}
	}
	
	// expose page object for other components access
	defineExpose({
		page
	});
</script>
<style scoped>
</style>
